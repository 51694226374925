//
//
//
//
//
//
//
//

export default {
  name: 'Loader',
  props: {
    w: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    h: {
      type: String,
      default: '',
    },
    loader: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    styles() {
      if (!this.loader) return {}
      return {
        height: this.h,
        maxWidth: this.w,
        ...(this.width && { width: this.width }),
      }
    },
  },
}
