//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'GCheckbox',
  model: {
    event: 'click',
    prop: 'active',
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'default',
    },
    disable: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'check',
    },
    w: {
      type: String,
      default: '16px',
    },
    h: {
      type: String,
      default: '16px',
    },
    iconW: {
      type: String,
      default: '65%',
    },
    iconH: {
      type: String,
      default: '65%',
    },
    stroke: {
      type: String,
      default: '#ffffff',
    },
    fill: {
      type: String,
      default: 'none',
    },
    offset: {
      type: String,
      default: '',
    },
    borderColor: {
      type: String,
      default: '',
    },
    bg: {
      type: String,
      default: '',
    },
    rules: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    styles() {
      return {
        ...(this.w && { width: this.w }),
        ...(this.h && { height: this.h }),
        ...(this.borderColor && { borderColor: this.borderColor }),
        ...(this.bg && { backgroundColor: this.bg }),
        ...(this.offset && this.$slots.append && { marginRight: this.offset }),
        ...(this.offset && this.$slots.prepend && { marginLeft: this.offset }),
      }
    },
    classesType() {
      return {
        'checkbox--default': this.type === 'default',
        'checkbox--secondary': this.type === 'secondary',
        'checkbox--primary': this.type === 'primary',
        'checkbox--disable': this.disable === true,
      }
    },
    isError() {
      if (this.errors) {
        return Array.isArray(this.errors) ? this.errors[0] : this.errors.message
      }
      if (!this.rules || !this.rules.$dirty) return

      const keys = Object.keys(this.rules.$params).filter(
        (key) => !this.rules[key],
      )
      return keys.length
        ? this.$t(`${keys[0]}`, {
          value: this.label,
          number: this.rules.$params[keys[0]].min,
        })
        : null
    },
  },
  methods: {
    onClick() {
      this.$emit('click', !this.active)
    },
  },
}
