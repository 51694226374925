//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'GToggleBox',
  model: {
    event: 'click',
    prop: 'active',
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'default',
    },
    disable: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'check',
    },
    w: {
      type: String,
      default: '42px',
    },
    h: {
      type: String,
      default: '24px',
    },
    iconW: {
      type: String,
      default: '75%',
    },
    iconH: {
      type: String,
      default: '75%',
    },
    stroke: {
      type: String,
      default: '#ffffff',
    },
    fill: {
      type: String,
      default: 'none',
    },
    offset: {
      type: String,
      default: '',
    },
  },
  computed: {
    styles() {
      return {
        ...(this.w && { width: this.w }),
        ...(this.h && { height: this.h }),
        ...(this.offset && this.$slots.append && { marginRight: this.offset }),
        ...(this.offset && this.$slots.prepend && { marginLeft: this.offset }),
      }
    },
    classesType() {
      return {
        'checkbox--default': this.type === 'default',
        'checkbox--disable': this.disable === true,
      }
    },
  },
  methods: {
    onClick() {
      this.$emit('click', !this.active)
    },
  },
}
