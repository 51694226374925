//
//
//
//
//
//

export default {
  name: 'Icon',
  props: {
    icon: {
      type: String,
      default: '',
      require: true,
    },
    w: {
      type: [Number, String],
      default: 24,
    },
    h: {
      type: [Number, String],
      default: 24,
    },
    color: {
      type: String,
      default: '',
    },
    stroke: {
      type: String,
      default: '',
    },
  },
  computed: {
    src() {
      return require('../../assets/images/icons-sprite.svg')
    },
    attr() {
      return {
        ...(this.color && { fill: this.color }),
        ...(this.stroke && { stroke: this.stroke }),
      }
    },
  },
}
