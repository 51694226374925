//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Button',
  props: {
    to: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    borderColor: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: '',
    },
    fontSize: {
      type: String,
      default: '',
    },
    rounded: {
      type: [String, Number],
      default: '0',
    },
    outline: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingStyle: {
      type: Object,
      default: () => ({}),
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      hover: false,
    }
  },
  computed: {
    stylesElement() {
      const obj = {}
      this.color && (obj.color = this.color)
      this.height && (obj.minHeight = this.height)
      this.height && (obj.maxHeight = this.height)
      this.width && (obj.width = this.width)
      this.maxWidth && (obj.maxWidth = this.maxWidth)
      this.fontSize && (obj.fontSize = this.fontSize)
      this.rounded &&
        (obj.borderRadius =
          typeof this.rounded === 'number'
            ? `${this.rounded}px`
            : /[^+\d]/.test(this.rounded)
              ? this.rounded
              : `${this.rounded}px`)
      this.backgroundColor && (obj.backgroundColor = this.backgroundColor)
      this.backgroundColor && (obj.borderColor = this.borderColor)
      return { ...obj, ...this.styles }
    },
    styleLoading() {
      let obj = {}
      this.loadingStyle && (obj = { ...obj, ...this.loadingStyle })
      return obj
    },
    classes() {
      return {
        'button--outline': this.outline === true,
        'button--disabled': this.disabled === true,
        'button--loading': this.loading === true,
        'button--danger': this.type === 'danger',
        'button--primary': this.type === 'primary',
        'button--success': this.type === 'success',
        'button--secondary': this.type === 'secondary',
        'button--black': this.type === 'black',
        'button--light-grey': this.type === 'light-grey',
      }
    },
  },
  methods: {
    convertHexToRGBA(hexCode, opacity) {
      if (!hexCode.includes('#')) return hexCode

      let hex = hexCode.replace('#', '')

      if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
      }

      const r = parseInt(hex.substring(0, 2), 16)
      const g = parseInt(hex.substring(2, 4), 16)
      const b = parseInt(hex.substring(4, 6), 16)

      return `rgba(${r},${g},${b},${opacity / 100})`
    },

    onClick(event) {
      this.$emit('click', event)
    },
  },
}
