//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'GlobalInputPhone',
  components: {},
  model: {
    event: 'input',
    prop: 'value',
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: [Array, Object],
      default: null,
    },
    touchForm: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      phone: '',
      invalidTelInput: false,
      propsTelephone: {
        mode: 'international',
        defaultCountry: 'PL',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: '',
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        maxLen: 14,
        validCharactersOnly: true,
      },
      inputOptions: {
        placeholder: '',
        showDialCode: true,
      }
    }
  },
  computed: {
    isError() {
      if (this.errors) {
        return Array.isArray(this.errors) ? this.errors[0] : this.errors.message
      }
      if (!this.rules || !this.rules.$dirty) return

      const keys = Object.keys(this.rules.$params).filter(
        (key) => !this.rules[key],
      )
      return keys.length
        ? this.$t(`${keys[0]}`, {
          value: this.label,
          number: this.rules.$params[keys[0]].min,
        })
        : null
    },
  },
  watch: {
    value(val) {
      if (val !== this.phone) {
        this.phone = val
      }
    },
  },
  created() {
    this.phone = this.value
  },
  methods: {
    validateTelephone(event) {
      if (event.valid !== undefined) {
        if(event.valid) {
          this.invalidTelInput = false
        } else {
          this.invalidTelInput = true
        }
        this.$emit('error', this.invalidTelInput)
      }
    },
    onInput(value) {
      this.$emit('input', value)
      if(this.errors) {
        this.$emit('changeError', this.id)
      }
    },
    onChange(value) {
      // this.$emit('input', value)
    },
  },
}
